.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Convergence Landing Page Styles */

/* Animations */
@keyframes gradientFlow {
  0% {
    background-position: 0% center;
  }
  100% {
    background-position: 200% center;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}

/* Define a sparkling animation */
@keyframes sparkle {
  0% {
    background-position: 0% 50%;
    opacity: 0.5;
  }
  50% {
    background-position: 100% 50%;
    opacity: 1;
  }
  100% {
    background-position: 0% 50%;
    opacity: 0.5;
  }
}

/* Base Styles */
.convergence-landing {
  min-height: 100vh;
  background-color: #0f0f1a;
  color: white;
  overflow-x: hidden;
  font-family: "Manrope", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 10;
}

/* Custom Cursor */
.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #6a9ad4;
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 50;
  display: none;
}

.nechepso-cursor {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 50;
  display: none;
  transform-origin: center;
  width: 30px;
  height: 30px;
}

.nechepso-cursor-arrow {
  position: absolute;
  width: 100%;
  height: 100%;
  clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
  background: linear-gradient(135deg, #6a9ad4, #a3c1e4);
  box-shadow: 0 0 10px rgba(106, 154, 212, 0.7);
  filter: drop-shadow(0 0 5px rgba(163, 193, 228, 0.8));
}

@media (min-width: 768px) {
  .custom-cursor {
    display: block;
  }
  
  .nechepso-cursor {
    display: block;
  }

  .nechepso-page {
    cursor: none;
  }
}

/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(163, 193, 228, 0.3);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #27548a;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #3b79be;
}

/* Constellation Background */
.constellation-bg {
  position: fixed;
  inset: 0;
  z-index: 0;
  pointer-events: none;
}

/* Gradient Text */
.gradient-text {
  background: linear-gradient(135deg, #6a9ad4, #a3c1e4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
  letter-spacing: -0.03em;
}

/* Section Divider */
.section-divider {
  position: relative;
  height: 30px;
  overflow: hidden;
}

.divider-inner {
  position: absolute;
  inset-x: 0;
  height: 30px;
}

.divider-line-1 {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to right, transparent, rgba(106, 154, 212, 0.2), transparent);
  transform: skewY(-2deg);
}

.divider-line-2 {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to right, transparent, rgba(106, 154, 212, 0.1), transparent);
  transform: skewY(2deg);
}

/* 3D Card */
.card-3d {
  position: relative;
  overflow: hidden;
  height: 100%;
  background: rgba(15, 15, 26, 0.6);
  border: 1px solid rgba(106, 154, 212, 0.2);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.card-bg {
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(106, 154, 212, 0.1), rgba(59, 121, 190, 0.05));
  border-radius: 16px;
  z-index: 0;
}

.card-border {
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(106, 154, 212, 0.2), transparent);
  border-radius: 16px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.card-3d:hover .card-border {
  opacity: 1;
}

.card-content {
  position: relative;
  z-index: 2;
  height: 100%;
  padding: 24px;
}

/* Custom Button */
.custom-button {
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
}

.explore-button {
  border: 1px solid #6a9ad4;
  border-radius: 9999px;
  padding: 12px 24px; /* Reduced padding */
  font-size: 16px; /* Slightly smaller font */
  font-weight: 600;
  color: white;
  position: relative;
  overflow: hidden;
  height: 44px; /* Fixed height */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button-text {
  position: relative;
  z-index: 10;
  transition: color 0.3s;
}

.explore-button:hover .button-text {
  color: black;
}

.button-bg {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to right, #6a9ad4, #a3c1e4);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s;
}

.explore-button:hover .button-bg {
  transform: scaleX(1);
}

/* Hero Section */
.hero-section {
  position: relative;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 50px;
  overflow: hidden;
}

.hero-bg {
  position: absolute;
  inset: 0;
  z-index: 10;
}

.hero-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 800px;
  border-radius: 50%;
  background-image: linear-gradient(
    to right,
    rgba(106, 154, 212, 0.2),
    rgba(59, 121, 190, 0.1),
    rgba(106, 154, 212, 0.2)
  );
  filter: blur(24px);
  animation: pulse 3s ease-in-out infinite;
}

.hero-image-container {
  margin-bottom: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
}

.hero-image {
  width: 100%;
  max-width: 700px;
  height: auto;
  margin: 0 auto;
  border-radius: 12px;
}

.hero-content {
  text-align: center;
}

.hero-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 24px;
  line-height: 1.2;
}

.hero-description {
  font-size: 20px;
  margin-bottom: 32px;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  color: #a3c1e4;
  line-height: 1.6;
  letter-spacing: 0.01em;
  text-shadow: 0 0 20px rgba(106, 154, 212, 0.3);
}

.highlight {
  color: #ffffff;
  font-weight: 700;
  position: relative;
  display: inline-block;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.highlight::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #ffffff, transparent);
}

.hero-cta {
  display: flex;
  justify-content: center;
}

.scroll-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 10;
}

.scroll-icon {
  width: 32px;
  height: 32px;
  color: #6a9ad4;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Features Section */
.features-section {
  padding: 80px 0;
  position: relative;
}

.section-header {
  text-align: center;
  margin-bottom: 64px;
}

.section-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 16px;
  letter-spacing: -0.03em;
}

.section-description {
  font-size: 20px;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  color: #a3c1e4;
}

.features-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  margin-bottom: 64px;
}

@media (min-width: 768px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .features-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.feature-card {
  background: rgba(15, 15, 26, 0.8);
  border: 1px solid rgba(106, 154, 212, 0.2);
  border-radius: 16px;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.feature-icon {
  margin-bottom: 16px;
  color: #6a9ad4;
}

.icon {
  width: 40px;
  height: 40px;
}

.feature-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #fff;
  letter-spacing: -0.02em;
}

.feature-description {
  color: rgba(163, 193, 228, 0.9);
  line-height: 1.6;
  flex-grow: 1;
}

.feature-showcase {
  position: relative;
  margin-top: 64px;
}

.showcase-bg {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to right, rgba(106, 154, 212, 0.2), transparent, rgba(106, 154, 212, 0.2));
  border-radius: 16px;
}

.showcase-content {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  width: 100%;
  aspect-ratio: 16 / 9; /* Maintain a consistent aspect ratio */
}

.showcase-image {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 16px;
  transition: opacity 0.5s ease-in-out;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  object-position: center; /* Center the image within the container */
}

.showcase-overlay {
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to top, #0f0f1a, transparent);
}

.showcase-text {
  padding: 24px 16px;
  text-align: center;
}

.showcase-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.showcase-description {
  color: #a3c1e4;
  margin-bottom: 16px;
}

.showcase-button {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.showcase-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.slider-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.slider-nav:hover {
  opacity: 1;
}

.slider-prev {
  left: 10px;
}

.slider-next {
  right: 10px;
}

.slider-indicators {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 10;
}

.slider-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 0;
  cursor: pointer;
}

.slider-indicator.active {
  background: white;
}

/* Experts Section */
.experts-section {
  padding: 80px 0;
  position: relative;
}

.experts-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  margin-bottom: 64px;
}

@media (min-width: 768px) {
  .experts-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.expert-card {
  padding: 32px;
  height: 100%;
}

.expert-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

@media (min-width: 768px) {
  .expert-content {
    flex-direction: row;
  }
}

.expert-image-container {
  position: relative;
  flex-shrink: 0;
}

.expert-image-glow {
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background-image: linear-gradient(to right, #6a9ad4, #3b79be);
  filter: blur(8px);
}

.expert-image {
  position: relative;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #6a9ad4;
}

.expert-info {
  text-align: center;
}

@media (min-width: 768px) {
  .expert-info {
    text-align: left;
  }
}

.expert-name {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
}

.expert-title {
  color: #a3c1e4;
  margin-bottom: 16px;
}

.expert-bio {
  margin-bottom: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

.expert-contributions {
  margin-top: 1rem;
}

.contributions-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--accent-color);
}

.contributions-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.contributions-list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.contributions-list li:before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: var(--accent-color);
}

.experts-cta {
  text-align: center;
}

/* Offer Section */
.offer-section {
  padding: 80px 0;
  position: relative;
}

.offer-bg {
  position: absolute;
  inset: 0;
}

.offer-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  background-image: linear-gradient(
    to right,
    rgba(106, 154, 212, 0.1),
    rgba(59, 121, 190, 0.05),
    rgba(106, 154, 212, 0.1)
  );
  filter: blur(24px);
  animation: pulse 3s ease-in-out infinite;
}

.offer-container {
  max-width: 1024px;
  margin: 0 auto;
}

.offer-card {
  padding: 32px;
}

.offer-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

@media (min-width: 768px) {
  .offer-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.offer-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.offer-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #6a9ad4;
}

.offer-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.offer-item {
  display: flex;
  align-items: flex-start;
}

.offer-badge {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom right, #6a9ad4, #3b79be);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-top: 4px;
}

.badge-icon {
  width: 20px;
  height: 20px;
  color: white;
}

.offer-item-title {
  font-weight: 700;
  color: white;
}

.offer-item-description {
  color: #a3c1e4;
}

.form-container {
  position: relative;
}

.form-bg {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 12px;
}

.form-content {
  position: relative;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.form-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.form-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: #6a9ad4;
}

.email-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #a3c1e4;
  margin-bottom: 4px;
}

.form-input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(106, 154, 212, 0.2);
  border-radius: 8px;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.form-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-input:focus {
  border-color: #6a9ad4;
  background: rgba(255, 255, 255, 0.1);
  outline: none;
}

.form-error {
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 4px;
}

.form-button {
  width: 100%;
  background-image: linear-gradient(to right, #6a9ad4, #3b79be);
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-image 0.3s;
}

.form-button:hover {
  background-image: linear-gradient(to right, #7baae4, #4b89ce);
}

.form-disclaimer {
  font-size: 12px;
  color: #a3c1e4;
}

.form-success {
  text-align: center;
  padding: 32px 0;
}

.success-icon-container {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #6a9ad4, #3b79be);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
}

.success-icon {
  width: 32px;
  height: 32px;
  color: white;
}

.success-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.success-message {
  color: #a3c1e4;
}

.final-cta {
  text-align: center;
  margin-top: 80px;
}

.final-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 32px;
}

.website-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
}

.link-text {
  position: relative;
  z-index: 10;
  background-image: linear-gradient(to right, #6a9ad4, #3b79be);
  padding: 12px 28px;
  border-radius: 9999px;
  color: white;
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.website-link:hover .link-text {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 0 0 30px rgba(106, 154, 212, 0.8);
}

.link-icon {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.link-glow {
  position: absolute;
  background-image: linear-gradient(to right, rgba(106, 154, 212, 0.5), rgba(59, 121, 190, 0.5));
  filter: blur(12px);
  padding: 12px 28px;
  border-radius: 9999px;
  opacity: 0.7;
  transition: opacity 0.3s, transform 0.3s, filter 0.3s;
}

.website-link:hover .link-glow {
  opacity: 1;
  transform: scale(1.2);
  filter: blur(8px);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(106, 154, 212, 0.8), rgba(59, 121, 190, 0.8), rgba(255, 255, 255, 0.8));
  background-size: 200% 100%;
  animation: sparkle 1.5s infinite;
}

/* Replace the previous after element with a more dramatic effect */
.website-link::after {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background: linear-gradient(45deg, 
    rgba(255,255,255,0) 0%, 
    rgba(255,255,255,0.8) 50%, 
    rgba(255,255,255,0) 100%);
  background-size: 200% 200%;
  top: -10%;
  left: -10%;
  border-radius: 9999px;
  opacity: 0;
  z-index: 5;
  transition: opacity 0.3s;
  pointer-events: none;
}

.website-link:hover::after {
  opacity: 1;
  animation: shine 1s infinite;
}

@keyframes shine {
  0% {
    background-position: -100% -100%;
  }
  100% {
    background-position: 100% 100%;
  }
}

/* Add sparkle points */
.website-link::before {
  content: '';
  position: absolute;
  width: 140%;
  height: 140%;
  background-image: 
    radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 20%),
    radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 20%),
    radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 20%),
    radial-gradient(circle, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0) 20%);
  background-size: 20% 20%, 15% 15%, 18% 18%, 25% 25%;
  background-position: 
    80% 20%, 
    20% 80%, 
    10% 10%, 
    90% 90%;
  background-repeat: no-repeat;
  top: -20%;
  left: -20%;
  z-index: 6;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.website-link:hover::before {
  opacity: 1;
  animation: twinkle 2s infinite;
}

@keyframes twinkle {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

/* Footer */
.site-footer {
  padding: 32px 0;
  position: relative;
}

.footer-copyright {
  color: #a3c1e4;
  text-align: center;
}

.footer-link {
  color: #a3c1e4;
  text-align: center;
  margin-top: 8px;
}

.website-url {
  color: #a3c1e4;
  text-decoration: none;
  transition: color 0.3s;
}

.website-url:hover {
  color: white;
}

@media (min-width: 768px) {
  .hero-title {
    font-size: 64px;
  }

  .section-title {
    font-size: 40px;
  }

  .hero-image {
    max-width: 700px;
  }
}

@media (min-width: 1024px) {
  .hero-image {
    max-width: 800px;
  }
}

@media (min-width: 1280px) {
  .hero-image {
    max-width: 900px;
  }
}

.scroll-text {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: #a3c1e4;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  opacity: 0.8;
  font-weight: 500;
  text-shadow: 0 0 10px rgba(106, 154, 212, 0.5);
}

/* Video Showcase Section */
.video-showcase-section {
  padding: 80px 0;
  position: relative;
  background: linear-gradient(180deg, rgba(15, 15, 26, 0) 0%, rgba(20, 20, 35, 0.4) 50%, rgba(15, 15, 26, 0) 100%);
}

/* Expanded video container - this is the red box area */
.expanded-video-container {
  width: 100%;
  margin: 40px 0;
  background: rgba(20, 20, 35, 0.6);
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid rgba(106, 154, 212, 0.3);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4);
  position: relative;
}

.expanded-video-wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  background-color: rgba(0, 0, 0, 0.4);
}

.expanded-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: rgba(0, 0, 0, 0.4);
}

.expanded-video-content {
  padding: 24px;
  background: rgba(15, 15, 26, 0.8);
}

/* Video grid for thumbnails */
.video-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  margin-top: 48px;
  position: relative;
}

@media (min-width: 768px) {
  .video-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .video-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.video-card {
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  z-index: 1;
}

.video-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
}

.video-card-inner {
  background: rgba(30, 30, 50, 0.5);
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid rgba(106, 154, 212, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
  aspect-ratio: 16 / 9;
}

.video-thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
}

.video-poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.video-thumbnail:hover .video-poster {
  transform: scale(1.05);
}

/* Play button on thumbnails */
.video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background: rgba(106, 154, 212, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0.9;
  clip-path: polygon(40% 30%, 40% 70%, 70% 50%);
}

.video-thumbnail:hover .video-play-button {
  background: rgba(163, 193, 228, 0.9);
  transform: translate(-50%, -50%) scale(1.1);
}

.video-content {
  padding: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.video-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 12px;
  color: #fff;
  background: linear-gradient(90deg, #a3c1e4, #6a9ad4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-description {
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0;
  flex-grow: 1;
}

/* Close button for expanded video */
.video-close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(15, 15, 26, 0.7);
  border: 1px solid rgba(106, 154, 212, 0.4);
  color: white;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 20;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.video-close-btn:hover {
  background: rgba(106, 154, 212, 0.8);
  opacity: 1;
  transform: scale(1.1);
}

/* Video section CTA */
.video-section-cta {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}

/* Add mobile-specific styling for showcase components */
@media (max-width: 767px) {
  .showcase-content {
    position: relative;
    overflow: hidden;
    border-radius: 16px 16px 0 0; /* Rounded corners only on top in mobile */
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  
  .showcase-bg {
    border-radius: 16px; /* Keep the background rounded on all corners */
    bottom: -100px; /* Extend the background to cover the text below */
    z-index: -1; /* Ensure it stays behind the content */
  }
  
  .showcase-overlay {
    display: none; /* Hide the overlay gradient on mobile */
  }
  
  .showcase-text {
    position: static; /* Position the text below the slider instead of absolute */
    bottom: auto;
    left: auto;
    right: auto;
    background-color: #0f0f1a; /* Match the site's background color */
    border-radius: 0 0 16px 16px;
    padding: 20px 16px;
    border-top: 1px solid rgba(106, 154, 212, 0.2); /* Add a subtle separator line */
  }
  
  .showcase-title {
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  
  .showcase-description {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.4;
  }
  
  .slider-nav {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
  
  .slider-indicators {
    bottom: 10px;
  }
  
  .slider-indicator {
    width: 8px;
    height: 8px;
  }
}

/* Keep the desktop styling for larger screens */
@media (min-width: 768px) {
  .showcase-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 32px;
  }
  
  .showcase-overlay {
    display: block; /* Show the overlay gradient on desktop */
  }
}

/* Modal Styling */
@media (max-width: 640px) {
  .modal-content {
    padding-right: 12px;
  }
  
  .modal-content::-webkit-scrollbar {
    width: 6px;
  }
}

/* Page Content Padding */
.page-content {
  padding-top: 120px; /* Doubled from 80px to create more space */
}

@media (max-width: 768px) {
  .page-content {
    padding-top: 100px; /* Doubled from 70px for mobile */
  }
}