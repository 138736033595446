@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Outhill';
  src: url('./fonts/Outhill.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* New styles for text effect */
.text-effect.highlight {
  background: linear-gradient(90deg, rgba(40, 1, 55, 0.3) 0%, rgba(40, 1, 55, 0.3) 100%);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  animation: highlight 2s forwards;
}

@keyframes highlight {
  to { background-size: 100% 100%; }
}